@import 'variables.scss';
i.fas, i.far, i.fal, i.fa {
    color: $ng4h-icons;
    &.ng4h-danger {
        color: $ng4h-danger;
    }
    &.fa-arrow-circle-left {
        cursor: pointer;
    }
  &.no-margin {
    margin: 0;
  }
}

.btn-primary i {
    color: $ng4h-secondary-light;
    margin-right: 8px;
}
.btn-secondary i {
  margin-right: 8px;
}

mat-icon{
  color: $ng4h-icons;
}

// button {
//     i.fa,
//     i.fas,
//     i.fab,
//     i.far {
//         color: white;
//     }
// }
